/* Global settings */
body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden; /* Prevent horizontal scroll */
  font-family: 'Source Code Pro', sans-serif; /* Ensure global font consistency */
}

.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-logo {
  height: 30vmin; /* Increased size for mobile within media query */
  margin-bottom: 20px;
  pointer-events: none;
  transform: scale(1.1); /* Slightly larger logo via scaling */
}

input[type="text"] {
  padding: 10px 20px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 25px;
  outline: none;
  transition: box-shadow 0.3s, border-color 0.3s;
  width: 80%;
  font-family: 'Source Code Pro', sans-serif; /* Ensuring input text matches */
}

input[type="text"]:focus {
  box-shadow: 0 0 10px rgba(130, 130, 130, 0.2);
  border-color: #000000;
}

.prompt-output {
  margin: 20px auto;
  width: 80%;
  height: auto;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  background-color: rgba(244, 244, 244, 0.9);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  white-space: pre-line;
  font-family: 'Source Code Pro', sans-serif; /* Consistent font for output */
}

.custom-btn {
  margin: 10px 5px;
  background-color: white;
  border: 1px solid black;
  color: black;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 4px;
  font-family: 'Source Code Pro', sans-serif; /* Explicit font family for buttons */
}

.custom-btn:hover {
  background-color: black;
  color: white;
}

.custom-btn:active {
  background-color: #575757;
  color: white;
}

.button-row {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 10px;
}

.generate-btn, .copy-btn, .custom-btn-midjourney {
  padding: 0.375rem 0.75rem;
  margin: 5px;
}

@font-face {
  font-family: 'Source Code Pro';
  src: local('Source Code Pro'), 
       url('./assets/fonts/SourceCodePro-Regular.ttf') format('woff2'), 
       url('./assets/fonts/SourceCodePro-Regular.ttf') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Source Code Pro';
  src: local('Source Code Pro Bold'), 
       url('./assets/fonts/SourceCodePro-Bold.ttf') format('woff2'), 
       url('./assets/fonts/SourceCodePro-Bold.ttf') format('woff');
  font-weight: bold;
  font-style: normal;
}

.App-header {
  background-image: url('./assets/images/background2.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: calc(10px + 2vmin);
}

.form-container {
  width: 90%;
  margin-top: 10px;
  margin-bottom: 20px;
}

.label {
  display: block;
  margin-bottom: 5px;
  color: white;
  text-shadow: 
    1px 1px 0 #000,  
    -1px -1px 0 #000,  
    -1px 1px 0 #000,
    1px -1px 0 #000;
}

/* Media Queries */
@media (max-width: 767px) {
  .prompt-output {
    height: 150px; /* Reduced height from 300px to 250px */
  }

  .button-row {
    flex-direction: column;
  }

  .custom-btn {
    width: 80%;
    margin: 10px auto;
  }
}
